import httpUtil from "@/utils/httpUtil";

/**获取类型商品列表 */
export const getGoodsInfoPCShop = async params => httpUtil.post("/api/crmPc/companyGoods/getGoodsInfoPCShop", params);


/**添加修改头部背景颜色 */
export const addColorValue = async params => httpUtil.post("/api/crmPc/companyGoods/addColorValue", params);


/**查询头部背景颜色 */
export const queryColorValue = async params => httpUtil.post("/api/crmPc/companyGoods/queryColorValue", params);



/**查询公司信息 */
export const getCompany = async params => httpUtil.post("/api/crmPc/companyGoods/getCompany", params);
